import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import SEO from '../components/SEO'
import Content from '../components/Content'
import WasThisHelpful from '../components/WasThisHelpful'
import Aside from '../components/Aside'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

export default function RestTemplate({ data, location }) {
  const post = data.markdownRemark

  return (
    <>
      <Nav location={location} />
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          padding: 40px 0px 40px 64px;
          margin: auto;
          max-width: 1200px;

          @media (max-width: 1100px) {
            padding: 40px 20px 40px 20px;
          }

          @media (max-width: 1100px) {
            padding: 40px 20px 40px 20px;
          }
        `}
      >
        <SEO
          description={post.frontmatter.description}
          title={post.headings[0].value}
          slug={post.fields.slug}
        />
        <Content>
          <main
            data-category={post.frontmatter.category}
            data-slug={post.fields.slug}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <WasThisHelpful />
          <Footer />
        </Content>
        <Aside>
          <div dangerouslySetInnerHTML={{ __html: post.tableOfContents }} />
        </Aside>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query RestAPIBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents(maxDepth: 2)
      frontmatter {
        title
        description
        flowclass
        category
      }
      headings {
        value
        depth
      }
      fields {
        slug
      }
    }
  }
`
