import React from 'react'
import Styles from '../styles/aside.module.scss'

const Aside = ({ children }) => {
  return (
    <div className={Styles.AsideWrapper}>
      <div className={Styles.Aside}>
        <h4>On This Page</h4>
        <div className={Styles.TableOfContents}>{children}</div>
      </div>
    </div>
  )
}

export default Aside
